import React, { useCallback, useRef } from "react";
import {
  Editor,
  RichUtils,
} from "draft-js";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";

import "draft-js/dist/Draft.css";
import "../styles/draft.custom.css";

function HomeEventDraftEditor(props) {
  const { editorState, setEditorState } = props;

  const editor = useRef(null);

  const _onH1Click = useCallback(() => {
    setEditorState(RichUtils.toggleBlockType(editorState, "header-one"));
  });

  const _onH2Click = useCallback(() => {
    setEditorState(RichUtils.toggleBlockType(editorState, "header-two"));
  });

  const _onH3Click = useCallback(() => {
    setEditorState(RichUtils.toggleBlockType(editorState, "header-three"));
  });

  const _onH4Click = useCallback(() => {
    setEditorState(RichUtils.toggleBlockType(editorState, "header-four"));
  });

  const _onH5Click = useCallback(() => {
    setEditorState(RichUtils.toggleBlockType(editorState, "header-five"));
  });

  const _onH6Click = useCallback(() => {
    setEditorState(RichUtils.toggleBlockType(editorState, "header-six"));
  });

  const _onBoldClick = useCallback(() => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, "BOLD"));
  });

  const _onItalicClick = useCallback(() => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, "ITALIC"));
  });

  const _onUnderlineClick = useCallback(() => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, "UNDERLINE"));
  });

  const focusOnEditor = () => editor.current.focus();

  return (
    <div className="bg-black bg-opacity-25 rounded-2 ">
      <div className="pt-3 pb-2 px-3 bg-gray bg-opacity-10">
        <ButtonGroup size="sm">
          <Button
            variant="outline-secondary"
            className="rounded-0"
            onClick={_onH1Click}
          >
            H1
          </Button>
          <Button
            variant="outline-secondary"
            className="rounded-0"
            onClick={_onH2Click}
          >
            H2
          </Button>
          <Button
            variant="outline-secondary"
            className="rounded-0"
            onClick={_onH3Click}
          >
            H3
          </Button>
          <Button
            variant="outline-secondary"
            className="rounded-0"
            onClick={_onH4Click}
          >
            H4
          </Button>
          <Button
            variant="outline-secondary"
            className="rounded-0"
            onClick={_onH5Click}
          >
            H5
          </Button>
          <Button
            variant="outline-secondary"
            className="rounded-0"
            onClick={_onH6Click}
          >
            H6
          </Button>
        </ButtonGroup>

        <ButtonGroup size="sm">
          <Button
            variant="outline-secondary"
            className="rounded-0"
            onClick={_onBoldClick}
          >
            Bold
          </Button>
          <Button
            variant="outline-secondary"
            className="rounded-0"
            onClick={_onItalicClick}
          >
            Italic
          </Button>
          <Button
            variant="outline-secondary"
            className="rounded-0"
            onClick={_onUnderlineClick}
          >
            Underline
          </Button>
        </ButtonGroup>
      </div>
      <hr className="border border-1 mx-3 my-1" />
      <div
        className="p-3"
        style={{ minHeight: "170px" }}
        onClick={focusOnEditor}
      >
        <Editor
          editorState={editorState}
          onChange={setEditorState}
          ref={editor}
        />
      </div>
    </div>
  );
}

export default HomeEventDraftEditor;
