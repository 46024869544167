import React from "react";
import HomeEventCard from "./HomeEventCard";

function HomeEventList(props) {
  const eventsElements = props.events.map((event) => {
    console.log(event.id);
    return <HomeEventCard key={`${event.id}`} event={event} />;
  });
  return <div className="vstack gap-3">{eventsElements}</div>;
}

export default HomeEventList;
