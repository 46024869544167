import React, { useState } from "react";
import Alert from "react-bootstrap/Alert";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, gql } from "@apollo/client";

const CREATE_USER_MUTATION = gql`
  mutation CreateUserMutation(
    $email: String!
    $firstName: String!
    $lastName: String!
    $password: String!
    $role: String!
    $username: String!
  ) {
    createUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      password: $password
      role: $role
      username: $username
    ) {
      user {
        id
        username
      }
    }
  }
`;

function AuthSignup(props) {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    firstname: "",
    lastname: "",
    role: "",
  });

  const [signupErrors, setSignupErrors] = useState([]);

  function handleSignupError(errors) {
    setSignupErrors((prevErrors) => errors);
  }

  const [signup] = useMutation(CREATE_USER_MUTATION, {
    variables: {
      username: formData.username,
      email: formData.email,
      password: formData.password,
      firstName: formData.firstname,
      lastName: formData.lastname,
      role: formData.role,
    },
    onCompleted: ({ createUser }) => {
      navigate("/login", {
        state: { signUpSuccessUser: createUser.user.username },
      });
    },
    onError: ({ message, graphQLErrors }) => {
      console.log(message);
      console.log(graphQLErrors);
      handleSignupError(graphQLErrors);
      console.log(signupErrors);
    },
  });

  async function handleSignup(event) {
    event.preventDefault();
    console.log("HANDLE LOGIN");
    await signup();
    console.log("HANDLED LOGIN");
  }

  function handleChange(event) {
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  }

  return (
    <div className="container-fluid m-0 p-0 vh-100">
      <div className="row p-0 m-0">
        <div className="col p-0 m-0 d-flex vh-100  bg-black bg-opacity-25">
          <div className="m-auto w-50">
            <h3 className="pb-4">
              Explore what's happening on the Aalto campus....
            </h3>
          </div>
        </div>
        <div className="col p-0 m-0 d-flex vh-100">
          <div className="m-auto w-75">
            <h3 className="pb-4">Sign Up</h3>
            <div>
              {signupErrors && (
                <>
                  {signupErrors.map((e, index) => (
                    <Alert key={index} variant="danger">
                      {e.message}
                    </Alert>
                  ))}
                </>
              )}
            </div>
            <form onSubmit={handleSignup}>
              <div className="row g-3">
                <div className="col-sm-6">
                  <label htmlFor="firstName" className="form-label">
                    First name
                  </label>
                  <input
                    type="text"
                    className="bg-black bg-opacity-25 form-control form-control-lg"
                    placeholder="John"
                    required=""
                    name="firstname"
                    value={formData.firstname}
                    onChange={handleChange}
                  />
                  <div className="invalid-feedback">
                    Valid first name is required.
                  </div>
                </div>

                <div className="col-sm-6">
                  <label htmlFor="lastName" className="form-label">
                    Last name
                  </label>
                  <input
                    type="text"
                    className="bg-black bg-opacity-25 form-control form-control-lg"
                    placeholder="Hippo"
                    required=""
                    name="lastname"
                    value={formData.lastname}
                    onChange={handleChange}
                  />
                  <div className="invalid-feedback">
                    Valid last name is required.
                  </div>
                </div>

                <div className="col-12">
                  <label htmlFor="username" className="form-label">
                    Username
                  </label>
                  <div className="input-group has-validation">
                    <input
                      type="text"
                      className="bg-black bg-opacity-25 form-control form-control-lg"
                      placeholder="angryhippo2022"
                      required=""
                      name="username"
                      value={formData.username}
                      onChange={handleChange}
                    />
                    <div className="invalid-feedback">
                      Your username is required.
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="bg-black bg-opacity-25 form-control form-control-lg"
                    placeholder="angryhippo2022@aalto.fi"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  <div className="invalid-feedback">
                    Please enter a valid email address.
                  </div>
                </div>

                <div className="col-12">
                  <label htmlFor="address" className="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    className="bg-black bg-opacity-25 form-control form-control-lg"
                    placeholder=""
                    required=""
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                  <div className="invalid-feedback">
                    Please enter your password
                  </div>
                </div>

                <div className="col-md-4">
                  <label htmlFor="state" className="form-label">
                    Sign up as ...
                  </label>
                  <select
                    className={`bg-black bg-opacity-25 form-select form-select-lg ${
                      formData.role === "" ? "" : "is-valid"
                    }`}
                    required=""
                    value={formData.role}
                    name="role"
                    onChange={handleChange}
                  >
                    <option disabled value="">
                      Choose...
                    </option>
                    <option value="ORGANIZER">Organizer</option>
                    <option value="PARTICIPANT">Participant</option>
                  </select>
                  <div className="invalid-feedback">
                    Please select a valid role
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center mt-5">
                <button
                  className="btn btn-primary"
                  style={{
                    "--bs-btn-padding-x": "2.3rem",
                    "--bs-btn-padding-y": "0.5rem",
                    color: "white",
                  }}
                >
                  Sign Up
                </button>{" "}
                <div className="hstack gap-3 m-3">
                  <div>
                    <span
                      style={{
                        color: "gray",
                      }}
                    >
                      Already a user?{"  "}
                    </span>
                    <Link to="/login">Log in</Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AuthSignup;
