import React, { useState, forwardRef } from "react";
import ReactDOMServer from "react-dom/server";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, gql } from "@apollo/client";

// Bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";

// FILEPOND FILE UPLOAD COMPONENTS
import {
  FilePond,
  registerPlugin as filePondRegisterPlugin,
} from "react-filepond";
import "filepond/dist/filepond.min.css";
import "../styles/filepond.custom.scss";

import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Draft.js Editor
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import HomeEventDraftEditor from "./HomeEventDraftEditor";

// REACT DATE PICKER
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// FONTAWESOME
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { faMapLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faLink } from "@fortawesome/free-solid-svg-icons";

filePondRegisterPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileEncode
);

const EVENT_DETAIL_QUERY = gql`
  query EventDetailQuery($id: UUID!) {
    eventById(id: $id) {
      id
      name
      coverImage
      coverImageUrl
      descJson
      startAt
      endAt
      place
      externalLink
      organizer {
        username
      }
    }
  }
`;

const CREATE_EVENT_MUTATION = gql`
  mutation CreateEventMutation(
    $coverImage: String!
    $descJson: JSONString!
    $name: String!
    $place: String!
    $startAt: DateTime!
    $endAt: DateTime!
    $externalLink: String!
  ) {
    createEvent(
      coverImage: $coverImage
      descJson: $descJson
      name: $name
      place: $place
      startAt: $startAt
      endAt: $endAt
      externalLink: $externalLink
    ) {
      event {
        id
      }
    }
  }
`;

const EDIT_EVENT_MUTATION = gql`
  mutation EditEventMutation(
    $id: UUID!
    $coverImage: String!
    $descJson: JSONString!
    $name: String!
    $place: String!
    $startAt: DateTime!
    $endAt: DateTime!
    $externalLink: String!
  ) {
    editEventById(
      id: $id
      coverImage: $coverImage
      descJson: $descJson
      name: $name
      place: $place
      startAt: $startAt
      endAt: $endAt
      externalLink: $externalLink
    ) {
      event {
        id
      }
    }
  }
`;

/*
 *
 * SOME USEFUL STUFF
 *
 * An image in base64:
 * "R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
 *
 */

function HomeEventCreateEdit() {
  const { eventId } = useParams();
  const EDIT_MODE = eventId ? true : false;

  console.log(eventId, EDIT_MODE);

  const navigate = useNavigate();

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [filePondImages, setFilePondImages] = useState([]);

  const [eventData, setEventData] = useState({
    name: "",
    // cover_image: "",
    // desc_json: {},
    start_date_time: new Date(),
    end_date_time: new Date(),
    place: "",
    external_link: "",
  });

  useQuery(EVENT_DETAIL_QUERY, {
    variables: {
      id: eventId,
    },
    onCompleted: ({ eventById }) => {
      const contentState = convertFromRaw(JSON.parse(eventById.descJson));
      setEditorState(EditorState.createWithContent(contentState));
      setEventData((prevEventData) => {
        return {
          name: eventById.name,
          cover_image: eventById.coverImage,
          desc_json: JSON.parse(eventById.descJson),
          start_date_time: Date.parse(eventById.startAt),
          end_date_time: Date.parse(eventById.endAt),
          place: eventById.place,
          external_link: eventById.externalLink,
        };
      });
    },
    fetchPolicy: "no-cache",
    skip: EDIT_MODE === false,
  });

  const [submitNewEvent] = useMutation(CREATE_EVENT_MUTATION, {
    variables: {
      name: eventData.name,
      coverImage: eventData.cover_image,
      // coverImages.length > 0
      //   ? coverImages[0].getFileEncodeBase64String()
      //   : "",
      descJson: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
      startAt: new Date(eventData.start_date_time).toISOString(),
      endAt: new Date(eventData.end_date_time).toISOString(),
      place: eventData.place,
      externalLink: eventData.external_link,
    },
    onCompleted: ({ createEvent }) => {
      navigate(`/events/${createEvent.event.id}`);
    },
    onError: ({ message, graphQLErrors }) => {
      console.log(message);
      console.log(graphQLErrors);
    },
  });

  const [editEvent] = useMutation(EDIT_EVENT_MUTATION, {
    variables: {
      id: eventId,
      name: eventData.name,
      coverImage: eventData.cover_image,
      // coverImages.length > 0
      //   ? coverImages[0].getFileEncodeBase64String()
      //   : "",
      descJson: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
      startAt: new Date(eventData.start_date_time).toISOString(),
      endAt: new Date(eventData.end_date_time).toISOString(),
      place: eventData.place,
      externalLink: eventData.external_link,
    },
    onCompleted: ({ updateEventById }) => {
      navigate(`/events/${eventId}`);
    },
    onError: ({ message, graphQLErrors }) => {
      console.log(message);
      console.log(graphQLErrors);
    },
  });
  //
  // Here are two types of "events"
  // 1. Events in A! that students can participate in
  // 2. Browser "events"
  //
  // Try no to be confused.
  //

  // const handleEventChange = (event) => {
  //   const { name, value, type, checked } = event.target;
  //   setEventData((prevFormData) => ({
  //     ...prevFormData,
  //     [name]: type === "checkbox" ? checked : value,
  //   }));
  // };

  function handleNameChange(newName) {
    setEventData((prevEventData) => {
      return {
        ...prevEventData,
        name: newName,
      };
    });
  }

  function handleCoverImageChange(newCoverImage) {
    setEventData((prevEventData) => {
      return {
        ...prevEventData,
        cover_image: newCoverImage,
      };
    });
  }

  function handleStartDateTimeChange(newStartDateTime) {
    setEventData((prevEventData) => {
      return {
        ...prevEventData,
        start_date_time: newStartDateTime,
      };
    });
  }

  function handleEndDateTimeChange(newEndDateTime) {
    setEventData((prevEventData) => {
      return {
        ...prevEventData,
        end_date_time: newEndDateTime,
      };
    });
  }

  function handlePlaceChange(newPlace) {
    setEventData((prevEventData) => {
      return {
        ...prevEventData,
        place: newPlace,
      };
    });
  }

  function handleExternalLinkChange(newExternalLink) {
    setEventData((prevEventData) => {
      return {
        ...prevEventData,
        external_link: newExternalLink,
      };
    });
  }

  // DatePicker
  const DateTimePill = forwardRef(({ value, onClick }, ref) => (
    <Button
      variant="dark"
      className="text-white bg-black bg-opacity-25 text-nowrap"
      onClick={onClick}
      ref={ref}
      style={{
        "--bs-btn-padding-x": "2rem",
        "--bs-btn-padding-y": "0.4rem",
        color: "white",
      }}
    >
      {value}
    </Button>
  ));

  return (
    <Row>
      <Col
        xs="0"
        sm="0"
        md="0"
        lg="1"
        xl="2"
        className="d-none d-lg-block"
      ></Col>
      <Col xs="12" sm="12" md="12" lg="10" xl="8" className="">
        <h2 className="py-4">
          {EDIT_MODE ? `Edit Event: ${eventData.name}` : `Create new event`}
        </h2>
        <div className="bg-light p-4 bg-opacity-10 rounded-4">
          <div className="row g-4">
            {/* NAME OF THE EVENT */}
            <div className="col-12">
              <input
                type="text"
                className="bg-black bg-opacity-25 form-control form-control-lg"
                id="Name"
                placeholder="Name of the event..."
                required=""
                value={eventData.name}
                onChange={(e) => handleNameChange(e.target.value)}
              />
            </div>

            {/* IMAGE UPLOAD */}
            <div className="col-12">
              <p className="mb-2">Cover Image</p>
              <FilePond
                files={filePondImages}
                onupdatefiles={(fileItems) => {
                  fileItems.map((fileItem) => {
                    // console.log(fileItem.getFileEncodeBase64String());
                    handleCoverImageChange(
                      fileItem.getFileEncodeBase64String()
                    );
                    // return null;
                  });
                  setFilePondImages(fileItems);
                }}
                allowMultiple={false}
                // server="/api"
                // storeAsFile={true}
                labelIdle={`${ReactDOMServer.renderToStaticMarkup(
                  <FontAwesomeIcon icon={faImage} />
                )} Add a cover image: Drag & Drop or <span class="filepond--label-action">Browse</span>`}
                className="mb-0 bg-black bg-opacity-25 text-light rounded-2"
                stylePanelLayout="integrated"
                allowFileEncode={true}
                credits={false}
              />
            </div>

            {/* EDITOR AREA */}

            <div className="col-12 mb-4">
              <p className="mb-2">Description</p>
              <HomeEventDraftEditor
                editorState={editorState}
                setEditorState={setEditorState}
              />
            </div>

            {/* DATETIME PICKER AREA */}

            <div className="col-12">
              <Stack direction="horizontal" gap={3}>
                <FontAwesomeIcon
                  icon={faCalendarDays}
                  className="fs-3 text-secondary px-2"
                  fixedWidth
                />
                <div className="d-flex align-items-center">
                  <ReactDatePicker
                    selected={eventData.start_date_time}
                    onChange={(date) => handleStartDateTimeChange(date)}
                    showTimeSelect
                    dateFormat={`Pp`}
                    customInput={<DateTimePill />}
                  />
                  <span className="px-3">to</span>
                  <ReactDatePicker
                    selected={eventData.end_date_time}
                    onChange={(date) => handleEndDateTimeChange(date)}
                    showTimeSelect
                    dateFormat={`Pp`}
                    customInput={<DateTimePill />}
                  />
                </div>
              </Stack>
            </div>

            {/* LOCATION PICKER AREA */}

            <div className="col-12">
              <Stack direction="horizontal" gap={3}>
                <FontAwesomeIcon
                  icon={faMapLocationDot}
                  className="fs-3 text-secondary px-2"
                  fixedWidth
                />
                <Row className="flex-grow-1">
                  <Col xs="8">
                    <input
                      type="text"
                      className="bg-black bg-opacity-25 form-control"
                      placeholder="Location of event"
                      value={eventData.place}
                      onChange={(e) => handlePlaceChange(e.target.value)}
                    />
                  </Col>
                </Row>
              </Stack>
            </div>

            {/* EXTERNAL LINK AREA */}

            <div className="col-12 mb-4">
              <Stack direction="horizontal" gap={3}>
                <FontAwesomeIcon
                  icon={faLink}
                  className="fs-3 text-secondary px-2"
                  fixedWidth
                />
                <Row className="flex-grow-1">
                  <Col xs="6">
                    <input
                      type="url"
                      className="bg-black bg-opacity-25 form-control"
                      placeholder="External link of the event..."
                      value={eventData.external_link}
                      onChange={(e) => handleExternalLinkChange(e.target.value)}
                    />
                  </Col>
                </Row>
              </Stack>
            </div>

            {/* BUTTON AREA */}

            <div className="col-12">
              <Stack direction="horizontal" gap={3}>
                <Button
                  variant="primary"
                  className="text-white"
                  style={{
                    "--bs-btn-padding-x": "2.3rem",
                    "--bs-btn-padding-y": "0.5rem",
                  }}
                  onClick={EDIT_MODE ? editEvent : submitNewEvent}
                >
                  {EDIT_MODE ? `Update` : `Publish`}
                </Button>
                <Button
                  variant="outline-light"
                  style={{
                    "--bs-btn-padding-y": "0.5rem",
                  }}
                >
                  Cancel
                </Button>
              </Stack>
            </div>
          </div>
        </div>
      </Col>

      <Col
        xs="0"
        sm="0"
        md="0"
        lg="1"
        xl="2"
        className="d-none d-lg-block"
      ></Col>
    </Row>
  );
}

export default HomeEventCreateEdit;
