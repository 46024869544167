import React, { useState } from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";


import cocktailCommitteeSvg from "../assets/home-index-my-groups-cocktail-committee.svg";
import handmadeOddsSvg from "../assets/home-index-my-groups-handmade-odds.svg";
import japaneseStudyGroupSvg from "../assets/home-index-my-groups-japanese-study-group.svg";
import mathNerdsSvg from "../assets/home-index-my-groups-math-nerds.svg";
import transportationHelpSvg from "../assets/home-index-my-groups-transportation-help.svg";

function HomeIndexMyGroups() {
  const [groups, setGroups] = useState([
    {
      hashtag: "#cocktail",
      stats: "12 Posted by this tag",
      img: cocktailCommitteeSvg,
    },
    {
      hashtag: "#handmade",
      stats: "23 Posted · Trending",
      img: handmadeOddsSvg,
    },
    {
      hashtag: "#japanese",
      stats: "34 Posted · Trending",
      img: japaneseStudyGroupSvg,
    },
    {
      hashtag: "#math",
      stats: "29 Posted by this tag",
      img: mathNerdsSvg,
    },
    {
      hashtag: "#travel",
      stats: "122 Posted · Trending",
      img: transportationHelpSvg,
    },
  ]);

  const groupsElement = groups.map((group) => {
    return (
      <Link to="/notimplemented" key={`${group.hashtag}`}>
        <div className="d-flex align-items-start pb-1">
          <img src={group.img} alt={`${group.hashtag}`} className="m-0 p-0" />
          <div className="ps-2">
            <p
              className="text-nowrap m-0 p-0 text-light "
              style={{
                fontSize: "small",
              }}
            >
              {group.hashtag}
            </p>
            <p
              className="text-nowrap m-0 p-0 text-light text-opacity-50"
              style={{
                fontSize: "x-small",
              }}
            >
              {group.stats}
            </p>
          </div>
        </div>
      </Link>
    );
  });

  return (
    <div className="bg-white bg-opacity-25 rounded-3 ps-3 py-3">
      <Link to="/groups">
        <h6 className="m-1 text-light">
          My Groups <FontAwesomeIcon icon={faArrowRight} />
        </h6>
      </Link>
      <div className="pt-2">{groupsElement}</div>
    </div>
  );
}

export default HomeIndexMyGroups;
