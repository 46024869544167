
import { createAvatar } from "@dicebear/avatars";
import * as dicebearAvatarStyle from "@dicebear/avatars-identicon-sprites";

function generateAvatar(seed, size=20) {
    return createAvatar(dicebearAvatarStyle, {
        seed: seed,
        size: size,
        backgroundColor: "#f0f0f0",
        dataUri: true,
    });
}

export {generateAvatar};