import React, { useState } from "react";
import { useOutletContext, Link } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";

import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";

import HomeEventList from "./HomeEventList";

import HomeIndexEventListFilterBox from "./HomeIndexEventListFilterBox";
import HomeIndexMyGroups from "./HomeIndexMyGroups";
import HomeIndexMyAgenda from "./HomeIndexMyAgenda";

import callToActionBackgroundImg from "../assets/call-to-action-background.png";

const ALL_EVENTS_QUERY = gql`
  {
    events {
      id
      name
      coverImage
      coverImageUrl
      descJson
      place
      startAt
      endAt
      externalLink
      organizer {
        id
        username
      }
      usersLike {
        id
      }
      usersParticipation {
        id
      }
    }
  }
`;

const MY_PARTICIPATED_EVENTS = gql`
  {
    me {
      role
      eventsParticipated {
        event {
          id
          name
          organizer {
            username
          }
          place
          startAt
        }
      }
    }
  }
`;

function HomeIndex(props) {
  const [user, setUser] = useOutletContext();
  const [events, setEvents] = useState([]);
  const [myParticipatedEvents, setMyParticipatedEvents] = useState([]);
  const [iAmOrganizer, setIAmOrganizer] = useState(false);

  const { data: allEventsData } = useQuery(ALL_EVENTS_QUERY, {
    onCompleted: ({ events }) => {
      setEvents((prevEvents) => events);
    },
  });

  const { data: myParticipatedEventsData } = useQuery(MY_PARTICIPATED_EVENTS, {
    onCompleted: ({ me }) => {
      if (me.eventsParticipated.length > 0) {
        setMyParticipatedEvents((prevEvents) => me.eventsParticipated);
      } else {
        setMyParticipatedEvents((prevEvents) => []);
      }
      setIAmOrganizer((prevIAmOrganizer) => me.role === "ORGANIZER");
    },
    skip: user === null,
    fetchPolicy: "no-cache",
  });

  return (
    <Row className="pt-4">
      <Col xs="0" sm="0" md="0" lg="0" xl="2" className="d-none d-xl-block">
        <HomeIndexEventListFilterBox />
        <HomeIndexMyGroups />
      </Col>
      <Col xs="12" sm="12" md="12" lg="9" xl="7" className="">
        <HomeEventList events={events} />
      </Col>
      <Col xs="0" sm="0" md="0" lg="3" xl="3" className="d-none d-lg-block">
        {iAmOrganizer ? (
          <>
            <div
              style={{
                backgroundImage: `url(${callToActionBackgroundImg})`,
                backgroundPosition: "center",
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
              }}
              className="p-4 mb-4"
            >
              <h5>Create Some Events!</h5>
              <p>
                Thanks to all organizers like you, Aalto is live, and vivid.
              </p>
              <Link to="/events/new">
                <div className="btn btn-light text-primary">
                  {" "}
                  Create New Event{" "}
                </div>
              </Link>
            </div>
          </>
        ) : null}
        {user ? (
          <HomeIndexMyAgenda
            myParticipatedEvents={myParticipatedEvents.map((pe) => pe.event)}
          />
        ) : (
          <div
            style={{
              backgroundImage: `url(${callToActionBackgroundImg})`,
              backgroundPosition: "center",
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
            }}
            className="p-4"
          >
            <h5>Join Us Today!</h5>
            <p>
              Explore and participate all the wonderful events that happen in A!
            </p>
            <Link to="/signup">
              <div className="btn btn-light text-primary"> Sign up </div>
            </Link>
          </div>
        )}
      </Col>
    </Row>
  );
}

export default HomeIndex;
