import React from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";

import { USER_ID } from "../constants";

import { generateAvatar } from "../utils";

function Header(props) {
  const { user } = props;

  return (
    <header className="p-0 m-0 bg-light bg-opacity-10">
      <div className="container ">
        <div className="d-flex flex-wrap align-items-center justify-content-between">
          <div className="d-flex align-items-center hstack gap-3">
            <a
              href="/"
              className="d-flex align-items-center text-decoration-none"
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="30" height="30" rx="6" fill="#F7F7F7" />
                <path
                  d="M6.98 15.42L6.564 16.98H10.178L9.788 15.42C9.56267 14.5707 9.32867 13.6693 9.086 12.716C8.86067 11.7627 8.63533 10.844 8.41 9.96H8.306C8.098 10.8613 7.88133 11.7887 7.656 12.742C7.448 13.678 7.22267 14.5707 6.98 15.42ZM0.844 24L6.148 7.048H10.75L16.054 24H11.998L10.958 19.97H5.784L4.744 24H0.844ZM17.0589 14.848L16.2269 13.184C17.0069 12.7853 17.5875 12.326 17.9689 11.806C18.3675 11.286 18.5669 10.6447 18.5669 9.882C18.5149 9.89933 18.4369 9.908 18.3329 9.908C17.7782 9.908 17.3015 9.74333 16.9029 9.414C16.5215 9.06733 16.3309 8.59933 16.3309 8.01C16.3309 7.35133 16.5129 6.83133 16.8769 6.45C17.2582 6.06867 17.7435 5.878 18.3329 5.878C19.1302 5.878 19.7369 6.18133 20.1529 6.788C20.5689 7.37733 20.7769 8.20933 20.7769 9.284C20.7769 11.78 19.5375 13.6347 17.0589 14.848ZM23.2209 14.848L22.3889 13.184C23.1689 12.7853 23.7495 12.326 24.1309 11.806C24.5295 11.286 24.7289 10.6447 24.7289 9.882C24.6769 9.89933 24.5989 9.908 24.4949 9.908C23.9402 9.908 23.4635 9.74333 23.0649 9.414C22.6835 9.06733 22.4929 8.59933 22.4929 8.01C22.4929 7.35133 22.6749 6.83133 23.0389 6.45C23.4202 6.06867 23.9055 5.878 24.4949 5.878C25.2922 5.878 25.8989 6.18133 26.3149 6.788C26.7309 7.37733 26.9389 8.20933 26.9389 9.284C26.9389 11.78 25.6995 13.6347 23.2209 14.848Z"
                  fill="#747EF8"
                />
              </svg>

              <h2 className="my-4 ps-2 text-primary">
                Aalto Events<span className="text-white">.</span>
              </h2>
            </a>

            <a href="/" className="ps-5">
              <div
                className="bg-primary bg-opacity-75 p-2 rounded-3 d-flex justify-content-center align-items-center"
                style={{
                  width: "38px",
                  height: "38px",
                }}
              >
                <FontAwesomeIcon
                  icon={faHouse}
                  className="text-white"
                  fixedWidth
                />
              </div>
            </a>
          </div>

          {user ? (
            <div className="hstack gap-3">
              <Link to={`/users/${JSON.parse(localStorage.getItem(USER_ID))}`}>
                <div className="d-flex align-items-center">
                  <img
                    src={generateAvatar(user.username)}
                    alt={`${user.username}`}
                    className="rounded-5 "
                  />
                  <p className="m-0 ps-2 text-white">{`${user.username}`}</p>
                </div>
              </Link>
              <Link to="logout">logout</Link>
            </div>
          ) : (
            <div className="hstack gap-4 ">
              <Link to="/login" className="text-light">
                <p className="m-0">login</p>
              </Link>
              <Link to="/signup">
                <button className="btn btn-primary small text-light">
                  Sign Up
                </button>
              </Link>
            </div>
          )}
        </div>
        {/* <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Dropdown Button
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
              <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
      </div>
    </header>
  );
}

export default Header;
