import React, { useState, useEffect } from "react";

import HomeLayout from "./components/HomeLayout";
import HomeIndex from "./components/HomeIndex";
import HomeEventCreateEdit from "./components/HomeEventCreateEdit";
import HomeEventDetail from "./components/HomeEventDetail";
import HomeUserDetail from "./components/HomeUserDetail";
import HomeOriginal from "./components/HomeOriginal";

import AuthLogin from "./components/AuthLogin";
import AuthSignup from "./components/AuthSignup";
import AuthLogout from "./components/AuthLogout";

import { Routes, Route } from "react-router-dom";
import NotImplemented from "./components/NotImplemented";

function App() {

  return (
    <>
      <Routes>
        <Route exact path="/" element={<HomeLayout />}>
          <Route index element={<HomeIndex />} />
          <Route exact path="/events/new" element={<HomeEventCreateEdit />} />
          <Route path="/events/:eventId/edit" element={<HomeEventCreateEdit />} />
          <Route path="/events/:eventId" element={<HomeEventDetail />} />
          <Route path="/users/:userId" element={<HomeUserDetail />} />
          {/* <Route path="/oldhome" element={<HomeOriginal />} /> */}
        </Route>
        <Route path="/login" element={<AuthLogin />} />
        <Route path="/signup" element={<AuthSignup />} />
        <Route path="/logout" element={<AuthLogout />} />
        <Route path="*" element={<NotImplemented />} />
      </Routes>
    </>
  );
}

export default App;
