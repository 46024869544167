import React, { useState } from "react";
import { useOutletContext, useParams, Link } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import userProfileSmallBgImg from "../assets/user-profile-small-bg.jpg";
import { generateAvatar } from "../utils";

import callToActionBackgroundImg from "../assets/call-to-action-background.png";

import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";

import HomeIndexMyAgenda from "./HomeIndexMyAgenda";
import HomeEventList from "./HomeEventList";

const USER_DETAIL_QUERY = gql`
  query userById($id: UUID!) {
    userById(id: $id) {
      id
      username
      role
      email
      firstName
      lastName
      eventsLiked {
        id
        name
        coverImageUrl
        organizer {
          id
          username
        }
        place
        startAt
        endAt
        externalLink
        usersLike {
          id
        }
        usersParticipation {
          id
        }
      }
      eventsOrganized {
        id
        name
        coverImage
        coverImageUrl
        organizer {
          id
          username
        }
        place
        startAt
        endAt
        externalLink
        usersLike {
          id
        }
        usersParticipation {
          id
        }
      }
      eventsParticipated {
        event {
          id
          name
          coverImage
          coverImageUrl
          organizer {
            id
            username
          }
          place
          startAt
          endAt
          externalLink
          usersLike {
            id
          }
          usersParticipation {
            id
          }
        }
      }
    }
  }
`;

function HomeUserDetail() {
  const [user, setUser] = useOutletContext();
  const { userId } = useParams();
  const [userDetail, setUserDetail] = useState({});
  const [eventsOrganized, setEventsOrganized] = useState([]);
  const [eventsLiked, setEventsLiked] = useState([]);
  const [eventsParticipated, setEventsParticipated] = useState([]);

  const { data: userDetailData } = useQuery(USER_DETAIL_QUERY, {
    variables: {
      id: userId,
    },
    onCompleted: ({ userById }) => {
      setUserDetail(userById);
      setEventsOrganized(userById.eventsOrganized);
      setEventsLiked(userById.eventsLiked);
      setEventsParticipated(userById.eventsParticipated.map((ep) => ep.event));
    },
    fetchPolicy: "no-cache",
  });

  // ====== USER DATA ARE PREPARED BEFORE THIS LINE ====== //

  return (
    <Row className="pt-4">
      <Col xs="0" sm="0" md="0" lg="0" xl="2" className="d-none d-xl-block">
        <div className="bg-light bg-opacity-10 rounded-4">
          {userDetail.role === "ORGANIZER" ? (
            <div className="p-4">
              <p>About</p>
              <p
                className="m-0 pb-1"
                style={{
                  fontSize: "small",
                }}
              >
                KY Events is a student association run by Business School
                students. It was founded for events, culture dicussions, and
                sports related matters. You can also follow KY Career and KY
                Today.
              </p>
            </div>
          ) : (
            <div className="">
              <img
                src={userProfileSmallBgImg}
                alt="decorator"
                style={{
                  maxWidth: "100%",
                }}
              />
              <div
                style={{
                  height: "350px",
                }}
                className="d-flex flex-column align-items-center justify-content-center pb-4"
              >
                <img
                  src={generateAvatar(userDetail.username, 60)}
                  alt={userDetail.usernmae}
                  className="rounded-5"
                />
                <p
                  className="pt-3 pb-0 m-0"
                  style={{
                    fontSize: "x-large",
                  }}
                >
                  {userDetail.firstName} {userDetail.lastName}
                </p>
                <p
                  className="p-0 m-0 text-light text-opacity-50"
                  style={{
                    fontSize: "x-small",
                  }}
                >
                  {userDetail.role}
                </p>
                <p
                  className="pt-4 m-0 "
                  style={{
                    fontSize: "small",
                  }}
                >
                  {userDetail.email}
                </p>
              </div>
            </div>
          )}
        </div>
      </Col>
      <Col xs="12" sm="12" md="12" lg="9" xl="7" className="">
        {userDetail.role === "ORGANIZER" ? (
          <>
            <div className="mb-4 p-3 bg-white bg-opacity-25 rounded-4">
              <img
                src={`https://picsum.photos/seed/${userDetail.id}/800/200`}
                className="rounded-3"
                style={{
                  maxWidth: "100%",
                }}
                alt={userDetail.username}
              />
              <span
                className="mt-3 mb-2 ms-2 d-inline-block"
                style={{
                  fontSize: "x-large",
                }}
              >
                {userDetail.username}
              </span>{" "}
              <span className="ps-3 text-light text-opacity-25">
                {" "}
                Organizer | {userDetail.eventsOrganized.length} events organized
              </span>
            </div>
            <HomeEventList events={eventsOrganized} />
          </>
        ) : (
          <>
          <h3 className="pb-3">Events liked</h3>
          <HomeEventList events={eventsLiked} />
          </>
        )}
      </Col>
      <Col xs="0" sm="0" md="0" lg="3" xl="3" className="d-none d-lg-block">
        {userDetail.role === "ORGANIZER" ? (
          <>
            <div
              style={{
                backgroundImage: `url(${callToActionBackgroundImg})`,
                backgroundPosition: "center",
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
                display: `${
                  !user || userDetail.username !== user.username ? "none" : "block"
                }`,
              }}
              className="p-4 mb-4"
            >
              <h5>Create Some Events!</h5>
              <p>
                Thanks to all organizers like you, Aalto is live, and vivid.
              </p>
              <Link to="/events/new">
                <div className="btn btn-light text-primary">
                  {" "}
                  Create New Event{" "}
                </div>
              </Link>
            </div>
            <HomeIndexMyAgenda myParticipatedEvents={eventsOrganized} />
          </>
        ) : (
          <HomeIndexMyAgenda myParticipatedEvents={eventsParticipated} />
        )}
      </Col>
    </Row>
  );
}

export default HomeUserDetail;
