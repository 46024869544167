import React from "react";

import { Link } from "react-router-dom";

export default function NotImplemented() {
  return (
    <div className="vh-100 container-fluid d-flex align-items-center justify-content-center">
      <div className="text-center">
        <h3>Page not found or not implemented yet</h3>
        <Link to="/">Go home</Link>
      </div>
    </div>
  );
}
