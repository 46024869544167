import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faSun } from "@fortawesome/free-solid-svg-icons";

function HomeIndexEventListFilterBox() {
  return (
    <div className="bg-white bg-opacity-25 rounded-3 p-2 mb-3">
      <Link to="/latest">
        <div className="p-2 mb-2 rounded-1 d-flex align-items-center">
          <div
            className="bg-dark bg-opacity-50 py-1 px-1 text-secondary rounded-1 d-flex justify-content-center align-items-center"
            style={{
              width: "33px",
              height: "33px",
            }}
          >
            <FontAwesomeIcon icon={faStar} fixedWidth />
          </div>
          <div className="ps-2">
            <h6 className="mb-0 text-white">Newest</h6>
            <small className="text-white text-opacity-50">
              Find latest events!
            </small>
          </div>
        </div>
      </Link>
      <Link to="/popular">
        <div className="p-2 rounded-1  d-flex align-items-center">
          <div
            className="bg-dark bg-opacity-50 py-1 px-1 text-danger rounded-1 d-flex justify-content-center align-items-center"
            style={{
              width: "33px",
              height: "33px",
            }}
          >
            <FontAwesomeIcon icon={faSun} fixedWidth />
          </div>
          <div className="ps-2">
            <h6 className="mb-0 text-white">Most Popular</h6>
            <small className="text-white text-opacity-50">
              Shots of the day!
            </small>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default HomeIndexEventListFilterBox;
