import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation, gql } from "@apollo/client";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import { convertFromRaw, Editor, EditorState, readOnly } from "draft-js";

import { format as DateTimeFormat } from "date-fns";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faHeart } from "@fortawesome/free-solid-svg-icons";
import { faMapLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faLink } from "@fortawesome/free-solid-svg-icons";

const EVENT_DETAIL_QUERY = gql`
  query EventDetailQuery($id: UUID!) {
    eventById(id: $id) {
      id
      name
      coverImage
      descJson
      startAt
      endAt
      place
      externalLink
      organizer {
        username
      }
      usersLike {
        username
      }
      usersParticipation {
        user {
          username
        }
      }
    }
  }
`;

const LIKE_EVENT_MUTATION = gql`
  mutation LikeEventMutation($eventId: UUID!) {
    likeEvent(eventId: $eventId) {
      like {
        event {
          id
        }
      }
    }
  }
`;

const UNLIKE_EVENT_MUTATION = gql`
  mutation UnlikeEventMutation($eventId: UUID!) {
    unlikeEvent(eventId: $eventId) {
      status
    }
  }
`;

const PARTICIPATE_IN_EVENT_MUTATION = gql`
  mutation ParticipateInEventMutation($eventId: UUID!) {
    participateInEvent(eventId: $eventId) {
      event {
        id
      }
    }
  }
`;

function HomeEventDetail() {
  const [user, setUser] = useOutletContext();
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [iLike, setILike] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [iAmOwner, setIAmOwner] = useState(false);
  const [iParticipated, setIParticipated] = useState(false);

  function participate() {
    setIParticipated((prevP) => {
      return true;
    });
  }

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const { data } = useQuery(EVENT_DETAIL_QUERY, {
    variables: {
      id: eventId,
    },
    onCompleted: ({ eventById }) => {
      const initContenteStateOfEvent = convertFromRaw(
        JSON.parse(eventById.descJson)
      );
      setLikeCount(eventById.usersLike.length);
      if (user && eventById.organizer.username === user.username) {
        setIAmOwner(true);
      }
      eventById.usersLike.map((like) => {
        if (user && like.username === user.username) {
          console.log("I like this event");
          setILike(true);
        }
      });
      eventById.usersParticipation.map((up) => {
        if (user && up.user.username === user.username) {
          console.log("I already opted-in.");
          participate(true);
        }
      });
      setEditorState((prevEditorState) => {
        return EditorState.createWithContent(initContenteStateOfEvent);
      });
    },
    fetchPolicy: "no-cache",
  });

  const [likeEvent] = useMutation(LIKE_EVENT_MUTATION, {
    variables: {
      eventId: eventId,
    },
    onCompleted: ({ likeEvent }) => {
      setILike(true);
      setLikeCount((prevLikeCount) => prevLikeCount + 1);
    },
    onError: ({ message, graphQLErrors }) => {
      console.log(message);
      console.log(graphQLErrors);
    },
  });

  const [unlikeEvent] = useMutation(UNLIKE_EVENT_MUTATION, {
    variables: {
      eventId: eventId,
    },
    onCompleted: ({ unlikeEvent }) => {
      setILike(false);
      setLikeCount((prevLikeCount) => prevLikeCount - 1);
    },
    onError: ({ message, graphQLErrors }) => {
      console.log(message);
      console.log(graphQLErrors);
    },
  });

  const [participateInEvent] = useMutation(PARTICIPATE_IN_EVENT_MUTATION, {
    variables: {
      eventId: eventId,
    },
    onCompleted: ({ participateInEvent }) => {
      participate();
    },
    onError: ({ message, graphQLErrors }) => {
      console.log(message);
      console.log(graphQLErrors);
    },
  });

  async function toggleLike() {
    if (iLike) {
      await unlikeEvent();
    } else {
      await likeEvent();
    }
  }

  let returnedContent = null;

  console.log(data);
  if (data) {
    returnedContent = (
      <Row>
        <Col
          xs="0"
          sm="0"
          md="0"
          lg="1"
          xl="2"
          className="d-none d-lg-block"
        ></Col>
        <Col xs="12" sm="12" md="12" lg="10" xl="8" className="pt-4">
          <div className="bg-light p-4 bg-opacity-10 rounded-4">
            <div className="row g-4">
              {/* NAME OF THE EVENT */}
              <div className="col-12">
                <h2>{data.eventById.name}</h2>
                <p className="text-white text-opacity-25">
                  created by @{data.eventById.organizer.username}
                </p>
              </div>

              {/* IMAGE UPLOAD */}
              <div className="col-12 d-flex justify-content-center">
                {data.eventById.coverImage && (
                  <>
                    <img
                      src={`data:image/jpeg;base64,${data.eventById.coverImage}`}
                      alt="coverImage"
                      style={{
                        maxHeight: "350px",
                        maxWidth: "100%",
                      }}
                    />
                  </>
                )}
              </div>

              {/* EDITOR AREA */}

              <div className="col-12 mb-4">
                {/* 
                    A KNOWN ISSUE WITH GRAMMAR CHECKERS (LIKE GRAMMARLY)
                    https://draftjs.org/docs/advanced-topics-issues-and-pitfalls#browser-pluginsextensions
                 */}
                <Editor editorState={editorState} readOnly={readOnly} />
              </div>

              {/* DATETIME PICKER AREA */}

              <div className="col-12">
                <Stack direction="horizontal" gap={3}>
                  <FontAwesomeIcon
                    icon={faCalendarDays}
                    className="fs-3 text-secondary px-2"
                    fixedWidth
                  />
                  <div className="d-flex align-items-center">
                    <div
                      variant="dark"
                      className="text-white text-nowrap rounded-2"
                      style={{
                        color: "white",
                        textDecoration: "underline",
                      }}
                    >
                      {DateTimeFormat(
                        new Date(data.eventById.startAt),
                        "MMMM dd, yyyy HH:mm:SS z"
                      )}
                    </div>
                    <span className="px-3">to</span>
                    <div
                      variant="dark"
                      className="text-white text-nowrap rounded-2"
                      style={{
                        color: "white",
                        textDecoration: "underline",
                      }}
                    >
                      {DateTimeFormat(
                        new Date(data.eventById.endAt),
                        "MMMM dd, yyyy HH:mm:SS z"
                      )}
                    </div>
                  </div>
                </Stack>
              </div>

              {/* LOCATION PICKER AREA */}

              <div className="col-12">
                <Stack direction="horizontal" gap={3}>
                  <FontAwesomeIcon
                    icon={faMapLocationDot}
                    className="fs-3 text-secondary px-2"
                    fixedWidth
                  />
                  <Row className="flex-grow-1">
                    <Col xs="8">
                      <p className="m-0">{data.eventById.place}</p>
                    </Col>
                  </Row>
                </Stack>
              </div>

              {/* EXTERNAL LINK AREA */}

              <div className="col-12 mb-4">
                <Stack direction="horizontal" gap={3}>
                  <FontAwesomeIcon
                    icon={faLink}
                    className="fs-3 text-secondary px-2"
                    fixedWidth
                  />
                  <Row className="flex-grow-1">
                    <Col xs="6">
                      <a href={data.eventById.externalLink}>
                        {data.eventById.externalLink}
                      </a>
                    </Col>
                  </Row>
                </Stack>
              </div>

              {/* BUTTON AREA */}
              <div className="col-12 mb-2">
                <Stack direction="horizontal" gap={3}>
                  <div
                    className={`d-flex align-items-center btn btn-primary bg-opacity-75 p-2 rounded-2 ${
                      user ? null : `disabled`
                    }`}
                    onClick={user ? toggleLike : null}
                  >
                    <FontAwesomeIcon
                      icon={faHeart}
                      className={
                        iLike ? "text-danger" : "text-light text-opacity-25"
                      }
                      fixedWidth
                    />
                    <p className="ps-1 m-0 text-light">{likeCount} Likes</p>
                  </div>

                  <div
                    className={`d-flex align-items-center btn btn-primary bg-opacity-75 btn-disable py-2 px-3 rounded-2 ${
                      user ? null : `disabled`
                    } ${user && iParticipated ? `disabled` : null}`}
                    onClick={user ? participateInEvent : null}
                  >
                    <p className="px-1 m-0 text-light">
                      {user && iParticipated
                        ? "See you there!"
                        : "Attend the Event"}
                    </p>
                  </div>
                  {iAmOwner ? (
                    <Link to={`/events/${eventId}/edit`}>
                      <div className="d-flex align-items-center text-secondary bg-opacity-75 btn-disable  rounded-2">
                        <p className="px-1 m-0">Edit Event</p>
                      </div>
                    </Link>
                  ) : null}
                </Stack>
              </div>
            </div>
          </div>
        </Col>

        <Col
          xs="0"
          sm="0"
          md="0"
          lg="1"
          xl="2"
          className="d-none d-lg-block"
        ></Col>
      </Row>
    );
  } else {
    returnedContent = (
      <div className="vh-100 container-fluid d-flex align-items-center justify-content-center">
        <div className="text-center">
          <h3>{`Event with ID ${eventId} is not found`}</h3>
          <Link to="/">Go home</Link>
        </div>
      </div>
    );
  }

  return returnedContent;
}

export default HomeEventDetail;
