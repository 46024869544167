import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "./styles/index.scss";
import App from "./App";
import { AUTH_TOKEN, LOGIN_INFO } from "./constants";
import { setContext } from "@apollo/client/link/context";

import {
  ApolloProvider,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";

const loginInfoStr = localStorage.getItem(LOGIN_INFO)

if (loginInfoStr) {
  const loginInfo = JSON.parse(loginInfoStr)
  if (new Date() > new Date(loginInfo.exp * 1000)) {
    localStorage.clear()
  }
}

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_GRAPHQL_ENDPOINT}`,
});

// THIS PART OF CODE COULD BE USEFUL LATER

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(AUTH_TOKEN);
  return {
    headers: {
      ...headers,
      authorization: token ? `Token ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  // link: httpLink,
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </Router>
  </React.StrictMode>
);
