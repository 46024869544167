import React, { useState } from "react";
import { Link } from "react-router-dom";
import { format as dateTimeFormat } from "date-fns";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faCircleUser,
  faHeart,
  faLink,
  faMapPin,
  faPeopleGroup,
} from "@fortawesome/free-solid-svg-icons";

import { Stack } from "react-bootstrap";

function HomeEventCard(props) {
  const { event } = props;

  console.log(event);

  return (
    <Link to={`/events/${event.id}`}>
      <div className="w-100 bg-light bg-opacity-25 rounded-3 px-3 py-4 d-flex text-white">
        <img
          src={`${process.env.REACT_APP_BACKEND_HOST}${event.coverImageUrl}`}
          style={{
            objectFit: "cover" /* Do not scale the image */,
            objectPosition: "left" /* Center the image within the element */,
            height: "170px",
            width: "190px",
          }}
          alt={`${event.name}`}
          className="rounded-3"
        />
        <div
          className="ps-3 d-flex flex-column"
          style={{
            minHeight: "170px",
          }}
        >
          <h5 className="pt-1">{`${event.name}`}</h5>
          <Stack direction="horizontal" gap={2}>
            <FontAwesomeIcon
              icon={faCalendarDays}
              className="text-secondary"
              fixedWidth
            />
            <small>
              <span className="pe-2">
                {`${dateTimeFormat(
                  new Date(event.startAt),
                  "MM/dd/yyyy HH:mm:SS"
                )}`}
              </span>
              to
              <span className="ps-2">
                {`${dateTimeFormat(
                  new Date(event.endAt),
                  "MM/dd/yyyy HH:mm:SS"
                )}`}
              </span>
            </small>
          </Stack>
          <Stack direction="horizontal" gap={2}>
            <FontAwesomeIcon
              icon={faMapPin}
              className="text-secondary"
              fixedWidth
            />
            <small>
              <span>{`${event.place}`}</span>
            </small>
          </Stack>
          <Stack direction="horizontal" gap={2}>
            <FontAwesomeIcon
              icon={faLink}
              className="text-secondary"
              fixedWidth
            />
            <small>
              <a
                href={`${event.externalLink}`}
                className="text-secondary"
              >{`${event.externalLink}`}</a>
            </small>
          </Stack>
          <div className="mt-auto mb-0 hstack gap-3">
            <div>
              <small>
                <FontAwesomeIcon
                  icon={faHeart}
                  className="text-white text-opacity-25"
                  fixedWidth
                />
              </small>
              <small className="ms-1">{`${event.usersLike.length}`}</small>
            </div>
            <div>
              <small>
                <FontAwesomeIcon
                  icon={faPeopleGroup}
                  className="text-white text-opacity-25"
                  fixedWidth
                />
              </small>
              <small className="ms-1">{`${event.usersParticipation.length}`}</small>
            </div>
            <div>
              <Link to={`/users/${event.organizer.id}`}>
              <small className="pb-3">
              <FontAwesomeIcon
                  icon={faCircleUser}
                  className="text-white text-opacity-25"
                  fixedWidth
                /> {`${event.organizer.username}`}
              </small>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default HomeEventCard;

//event data
/*  const event = [
    {
      image: avatarImage,
      name: "From Kishan Sheth",
      time: "Today, 16:36",
      amount: "+$50",
    },
    {
      image: avatarImage,
      name: "To Lauras Santos",
      time: "Today, 08:49",
      amount: "-$25",
    },
    {
      image: avatarImage,
      name: "From Jadon S.",
      time: "Yesterday, 14:36",
      amount: "+$150",
    },
  ]; */
