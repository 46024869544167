import React from "react";

function Footer() {
  return (
    <footer className="container py-5 mt-auto d-flex justify-content-center">
      <p>@copyright by Tapahtumat team</p>
    </footer>
  );
}
export default Footer;
