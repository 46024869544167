import React from "react";
import { Link } from "react-router-dom";
import { format as dateFnsFormat } from "date-fns";

import { generateAvatar } from "../utils";

function HomeIndexMyAgenda(props) {
  const { myParticipatedEvents } = props;

  console.log(myParticipatedEvents);

  return (
    <div className="bg-white bg-opacity-25 rounded-3 py-3 px-3">
      <h5 className="ps-2 pt-2">Agenda</h5>
      {myParticipatedEvents && myParticipatedEvents.length > 0 ? (
        myParticipatedEvents.map((pe) => {
          return (
            <Link to={`/events/${pe.id}`}>
              <div className="d-flex pt-2 align-items-center">
                <div
                  className="d-flex flex-column align-items-center rounded-1"
                  style={{
                    backgroundColor: "#2c353d",
                    padding: "8px 10px 5px 10px",
                  }}
                >
                  <p
                    className="p-0 m-0 text-light"
                    style={{
                      fontSize: "small",
                    }}
                  >
                    {dateFnsFormat(
                      new Date(pe.startAt),
                      "MMM"
                    ).toUpperCase()}
                  </p>
                  <p
                    className="p-0 m-0 text-primary"
                    style={{
                      fontSize: "x-large",
                    }}
                  >
                    {dateFnsFormat(
                      new Date(pe.startAt),
                      "dd"
                    ).toUpperCase()}
                  </p>
                </div>
                <div className="ps-2">
                  <h6
                    className="p-0 m-0 text-light"
                    style={{
                      fontSize: "small",
                    }}
                  >
                    {pe.name}
                  </h6>
                  <img
                    src={generateAvatar(pe.organizer.username)}
                    alt={pe.organizer.username}
                    style={{
                      width: "12px",
                      height: "12px",
                    }}
                  />
                  <span
                    className="text-light text-opacity-50 ps-2"
                    style={{
                      fontSize: "small",
                    }}
                  >
                    {pe.organizer.username} · {pe.place}
                  </span>
                  <div className="hstack gap-1 pt-1">
                    <span
                      className=" badge text-bg-dark text-light text-opacity-25 p-1 m-0"
                      style={{
                        fontSize: "x-small",
                      }}
                    >
                      #random
                    </span>
                    <span
                      className=" badge text-bg-dark text-light text-opacity-25 p-1 m-0"
                      style={{
                        fontSize: "x-small",
                      }}
                    >
                      #random
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          );
        })
      ) : (
        <div>
          <p className="text-white text-opacity-50 px-2 py-1 m-0">
            Doesn't have any event yet.
          </p>
        </div>
      )}
    </div>
  );
}

export default HomeIndexMyAgenda;
