import React, { useState, useEffect } from "react";
import { LOGIN_INFO } from "../constants";

import Header from "./Header";
import Footer from "./Footer";

import { Outlet } from "react-router-dom";

// LAYOUT COMPONENT, DO NOT EDIT

function HomeLayout(props) {
  const [user, setUser] = useState({});

  const loginInfoFromLocalStorage = localStorage.getItem(LOGIN_INFO);

  useEffect(() => {
    if (loginInfoFromLocalStorage) {
      setUser((prevUser) => JSON.parse(loginInfoFromLocalStorage));
    } else {
      setUser(null);
    }
  }, [loginInfoFromLocalStorage]);

  return (
    <div className="d-flex flex-column h-100">
      <Header user={user} />
      <main className="container flex-shrink-0">
        <Outlet context={[user, setUser]} />
      </main>
      <Footer />
    </div>
  );
}

export default HomeLayout;
