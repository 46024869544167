import React, { useState } from "react";
import Alert from "react-bootstrap/Alert";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useMutation, gql } from "@apollo/client";

import { AUTH_TOKEN, LOGIN_INFO, USER_ID } from "../constants";

const LOGIN_MUTATION = gql`
  mutation TokenAuthMutation($username: String!, $password: String!) {
    tokenAuth(input: {username: $username, password: $password}) {
      token
      refreshExpiresIn
      payload
      user {
        id
      }
    }
  }
`;

function AuthLogin(props) {
  const location = useLocation();

  const { state } = location;
  // if (state) {
  //   const { signUpSuccessUser } = state;
  // } else {
  //   const signUpSuccessUser = null;
  // }

  const signUpSuccessUser =
    state && state.signUpSuccessUser ? state.signUpSuccessUser : null;

  const navigate = useNavigate();

  const [loginErrors, setLoginErrors] = useState([]);

  function handleLoginErrors(errors) {
    setLoginErrors((prevErrors) => errors);
  }

  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const [login] = useMutation(LOGIN_MUTATION, {
    variables: {
      username: formData.username,
      password: formData.password,
    },
    onCompleted: ({ tokenAuth }) => {
      localStorage.setItem(AUTH_TOKEN, tokenAuth.token);
      localStorage.setItem(LOGIN_INFO, JSON.stringify(tokenAuth.payload));
      localStorage.setItem(USER_ID, JSON.stringify(tokenAuth.user.id));
      navigate("/");
    },
    onError: ({ message, graphQLErrors }) => {
      console.log(message);
      console.log(graphQLErrors);
      handleLoginErrors(graphQLErrors);
      console.log(loginErrors);
    },
  });

  async function handleLogin(event) {
    event.preventDefault();
    console.log("HANDLE LOGIN");
    await login();
    console.log("HANDLED LOGIN");
  }

  function handleChange(event) {
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  }

  return (
    <div className="container-fluid m-0 p-0 vh-100">
      <div className="row p-0 m-0">
        <div className="col p-0 m-0 d-flex vh-100  bg-black bg-opacity-25">
          <div className="m-auto w-50">
            <h3 className="pb-4">
              Explore what's happening on the Aalto campus....
            </h3>
          </div>
        </div>
        <div className="col p-0 m-0 d-flex vh-100">
          <div className="m-auto w-75">
            <h3 className="pb-4">
              {signUpSuccessUser
                ? `Welcome ${signUpSuccessUser}! Please log in`
                : `Log In`}
            </h3>
            <div>
              {loginErrors && (
                <>
                  {loginErrors.map((e, index) => (
                    <Alert key={index} variant="danger">
                      {e.message}
                    </Alert>
                  ))}
                </>
              )}
            </div>
            <form onSubmit={handleLogin}>
              <div className="mb-3">
                <label className="form-label  mb-3">Username</label>
                <input
                  type="text"
                  className="bg-black bg-opacity-25 form-control form-control-lg"
                  placeholder="Username"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label mb-3">Password</label>
                <input
                  type="password"
                  className="bg-black bg-opacity-25 form-control form-control-lg"
                  placeholder="Password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />
              </div>
              <button
                className="btn btn-primary mt-3"
                style={{
                  "--bs-btn-padding-x": "2rem",
                  "--bs-btn-padding-y": "0.4rem",
                  color: "white",
                }}
              >
                Log in
              </button>
            </form>
            <div className="hstack gap-3 mt-5">
              <div>
                <small>
                  <Link to="/signup">Sign Up</Link>
                </small>
              </div>
              <div className="vr"></div>
              <div>
                <small>
                  <Link to="/reset-password">Forgot your password?</Link>
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AuthLogin;
